import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setFilterData } from "../../../../redux/events/actions";
import { EventFilterData } from "../../../../models/eventData";
import { AppState } from "../../../../redux";

const ChangeEventFilterForm: React.FC = () => {
  const dispatch = useDispatch();
  const eventState = useSelector((state: AppState) => state.event);
  const { values, initialValues } = useFormikContext<EventFilterData>();

  useEffect(() => {
    if (
      JSON.stringify(values) !== JSON.stringify(eventState.filterData) &&
      JSON.stringify(values) !== JSON.stringify(initialValues) &&
      !eventState.isLoading
    ) {
      dispatch(setFilterData(values));
    }
  }, [initialValues, values, eventState.filterData, eventState.isLoading, dispatch]);

  return null;
};

export default ChangeEventFilterForm;
