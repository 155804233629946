import moment from "moment";
import * as yup from "yup";
import {
  DATE_INVALID,
  BIRTHDATE_REQUIRED,
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
} from "./validation_strings";
import { useTranslation } from "react-i18next";
import { NIL as NIL_UUID } from "uuid";
import { userNeedsAGuardian } from "../pages/auth/registration/RegistrationPage.utils";

const specialChars = "!\"#$%&'()*+,-./:;<=>?@[\\]^`{|}~";

/**
 * Returns a normalized string, removing multiple whitespaces and replacing ß.
 * @param {string} inputString The string to be normalized.
 * @returns {string} The normalized string.
 */
const NormalizeString = (inputString: string): string => {
  return inputString
    .toLowerCase()
    .replace(/\s+/g, " ")
    .replace(/ß/g, "ss")
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue")
    .trim();
};

const CleanMatchNames = (nameSet: string, nameInput: string | undefined) => {
  if (nameInput) {
    const cleanedNameInput = NormalizeString(nameInput);
    const cleanedNameSet = NormalizeString(nameSet);
    return new RegExp(`\\b${cleanedNameSet}\\b`, "i").test(cleanedNameInput);
  }
  return false;
};

export const useDynamicYupValidations = () => {
  const { t } = useTranslation(["validations"]);

  const YupValidationPasswordfRequired = yup
    .string()
    .required(t("password.passwordRequired"));

  const YupValidationPassword = yup
    .string()
    .required(t("password.passwordRequired"))
    .min(8, t("password.passwordLen"))
    .matches(/\d/, t("password.passwordDigit"))
    .matches(/[A-Z]/, t("password.passwordUpperCase"))
    .matches(/[a-z]/, t("password.passwordLowerCase"))
    .matches(
      /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
      t("password.passwordSpecialChar", { specialChars: specialChars })
    );

  const YupValidationConfirmPassword = (fieldReference: string) =>
    YupValidationPassword.oneOf(
      [yup.ref(fieldReference)],
      t("password.passwordIdentical")
    );

  const YupValidationGuardianEmail = (minimumAge: number, email: string | undefined) =>
    yup.string().when("birthday", {
      is: (birthday: string) => {
        return userNeedsAGuardian(birthday, minimumAge);
      },
      then: yup
        .string()
        .email(t("email.emailInvalid", { ns: "validations" }))
        .required(t("email.emailGuardianRequired", { ns: "validations" }))
        .test(
          "not-same-email",
          t("email.emailGuardianNotSame", { ns: "validations" }),
          function (value) {
            return value !== email;
          }
        ),
    });

  const YupValidationConfirmGuardianEmail = (email: string) =>
    yup.string().oneOf([yup.ref(email)], t("email.emailNoMatch"));

  const YupValidationFirstName = yup.string().required(t(FIRST_NAME_REQUIRED));

  const YupValidationMatchFirstName = (firstNameSet: string) =>
    yup
      .string()
      .required(t(FIRST_NAME_REQUIRED))
      .test("firstname-match", t("firstName.firstNameMatch"), function (nameInput) {
        return CleanMatchNames(firstNameSet, nameInput);
      });

  const YupValidationLastName = yup.string().required(t(LAST_NAME_REQUIRED));

  const YupValidationMatchLastName = (lastNameSet: string) =>
    yup
      .string()
      .required(t(LAST_NAME_REQUIRED))
      .test("lastname-match", t("lastName.lastNameMatch"), function (nameInput) {
        return CleanMatchNames(lastNameSet, nameInput);
      });

  const YupValidationEmail = yup
    .string()
    .email(t(EMAIL_INVALID))
    .required(t(EMAIL_REQUIRED));

  const YupValidationConfirmEmail = (fieldReference: string) =>
    yup
      .string()
      .oneOf([yup.ref(fieldReference)], t("email.emailNoMatch"))
      .required(t(EMAIL_REQUIRED));

  const YupValidationBirthday = yup
    .date()
    .when({
      is: (value: Date) => {
        return value !== null;
      },
      then: yup.date().typeError(t(DATE_INVALID)),
    })
    .max(new Date(), t("dates.birthdateFuture"))
    .required(t(BIRTHDATE_REQUIRED));

  const YupValidationMatchBirthday = (birthdaySet: string) =>
    yup
      .date()
      .typeError(t(DATE_INVALID))
      .required(t(BIRTHDATE_REQUIRED))
      .test("birthdays-match", t("dates.birthdateMatch"), function (birthdayInput) {
        if (birthdayInput) {
          return birthdaySet === moment(birthdayInput).format("DD.MM.YYYY");
        }
        return false;
      });

  const YupValidationDueDate = yup
    .date()
    .typeError(t("dates.dueDateInvalid"))
    .required(t("dates.dueDateRequired"));

  const YupValidationStreet = yup.string().required(t("streetRequired"));

  const YupValidationStreetNumber = yup.string().required(t("streetNumberRequired"));

  const YupValidationCity = yup.string().required(t("cityRequired"));

  const YupValidationZipCode = yup
    .string()
    .matches(/^\d{5}$/, t("zipCode.zipCodeInvalid"))
    .required(t("zipCode.zipCodeRequired"));

  const YupValidationPhoneOptional = yup
    .string()
    .nullable()
    .matches(/^\+?\d+$/, t("phoneNumber.phoneNumberInvalid"));

  const YupValidationPhone = yup
    .string()
    .required(t("phoneNumber.phoneNumberRequired"))
    .matches(/^\+?\d+$/, t("phoneNumber.phoneNumberInvalid"));

  const YupValidationOrganizationName = yup.string().required(t("organizationRequired"));

  const YupValidationOrganizationType = yup
    .string()
    .required(t("organizationTypeRequired"));

  const YupValidationOrganizationTaxNumber = yup
    .string()
    .required(t("taxNumberRequired"));

  const YupValidationOrganizationTaxNumberSelected = (
    referenceField: string,
    referenceField2: string
  ) =>
    yup.string().test(referenceField, t("taxNumberRequired"), function () {
      const ref = yup.ref(referenceField);
      const ref2 = yup.ref(referenceField2);
      const resolveRef = this.resolve(ref);
      const resolveRef2 = this.resolve(ref2);

      if (resolveRef === undefined && resolveRef2 === undefined) {
        return false;
      }
      return true;
    });

  const YupValidationGroupDocumentDateSelected = (
    referenceField: string,
    referenceField2: string
  ) =>
    yup
      .date()
      .typeError(t("dates.dueDateInvalid"))
      .min(new Date(new Date().setDate(new Date().getDate() - 1)), t("dates.dateFuture"))
      .test(referenceField, t("expiryDateValueRequired"), function () {
        const ref = yup.ref(referenceField);
        const ref2 = yup.ref(referenceField2);
        const resolveRef = this.resolve(ref);
        const resolveRef2 = this.resolve(ref2);

        if (resolveRef === undefined && resolveRef2 === false) {
          return false;
        } else if (resolveRef === null && resolveRef2 === false) {
          return false;
        }
        return true;
      });

  const YupValidationOrganizationCommercialRegisterNumber = yup
    .string()
    .required(t("commercialRegisterNumberRequired"));

  const YupValidationTaskDescription = yup.string().required(t("organizationRequired"));

  const YupValidationTaskDeadline = yup.number().min(0, t("dates.deadlineInvalid"));

  const YupValidationOrganizationID = yup
    .string()
    .required(t("organizationRequired"))
    .test("organizationIDnotNIL", t("organizationRequired"), function (organizationID) {
      return organizationID !== NIL_UUID;
    });

  const YupValidationMessage = yup.string().required(t("messageRequired"));

  const YupValidationTopic = yup.string().required(t("topicRequired"));

  const YupValidationSalutation = yup.string().required(t("salutationRequired"));

  const YupValidationGroupMembershipDocumentType = (
    groupMembershipDocumentTypeOptions: any
  ) =>
    yup.string().oneOf(
      groupMembershipDocumentTypeOptions.map((documentTypeOption: any) => {
        return documentTypeOption.value;
      }),
      t("invalidSelection", { ns: "validations" })
    );

  return {
    YupValidationPassword,
    YupValidationConfirmPassword,
    YupValidationFirstName,
    YupValidationMatchFirstName,
    YupValidationLastName,
    YupValidationMatchLastName,
    YupValidationEmail,
    YupValidationConfirmEmail,
    YupValidationBirthday,
    YupValidationMatchBirthday,
    YupValidationDueDate,
    YupValidationStreet,
    YupValidationStreetNumber,
    YupValidationCity,
    YupValidationZipCode,
    YupValidationPhoneOptional,
    YupValidationPhone,
    YupValidationOrganizationName,
    YupValidationOrganizationTaxNumber,
    YupValidationOrganizationCommercialRegisterNumber,
    YupValidationOrganizationTaxNumberSelected,
    YupValidationOrganizationType,
    YupValidationTaskDescription,
    YupValidationTaskDeadline,
    YupValidationOrganizationID,
    YupValidationMessage,
    YupValidationTopic,
    YupValidationSalutation,
    YupValidationGuardianEmail,
    YupValidationConfirmGuardianEmail,
    YupValidationPasswordfRequired,
    YupValidationGroupDocumentDateSelected,
    YupValidationGroupMembershipDocumentType,
  };
};
