/* eslint-disable react-hooks/exhaustive-deps, react/jsx-max-depth */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserData } from "../../../../redux/users/actions";
import { AppState } from "../../../../redux";
import Loader from "../../../theming/loader/Loader";
import { useTranslation } from "react-i18next";
import { getGroupMembershipDocumentTypes } from "../../../../redux/groupMembershipDocumentType/actions";
import { SCHOOL_STUDENT_CARD_LABEL } from "../../../../constants";
import { getCurrentLanguage } from "../../localization/getStringTranslation";
import { MembershipDocumentUploadArea } from "./MembershipDocumentUploadArea";
import { MembershipDocumentSelection } from "./MembershipDocumentSelection";
import { MembershipDocumentRequestDto } from "../../../../models/groupMembership";
import { FileObject } from "material-ui-dropzone";

interface AddMembershipDocumentProps {
  isRegistrationPage: boolean;
  groupMembershipDocument: MembershipDocumentRequestDto;
  setGroupMembershipDocument: Dispatch<SetStateAction<MembershipDocumentRequestDto>>;
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

const currentDateLocal = new Date();

export const AddMembershipDocumentForm: React.FC<AddMembershipDocumentProps> = (
  props
) => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const {
    isRegistrationPage,
    groupMembershipDocument,
    setGroupMembershipDocument,
    files,
    setFiles,
  } = props;

  let user = currentUser;
  if (isRegistrationPage) {
    user = undefined;
  }

  const [documentTypeIsSelected, setDocumentTypeIsSelected] = useState<boolean>(false);

  const [userIsLoading, setUserIsLoading] = useState<boolean>(false);

  const maxAge = process.env.REACT_APP_MAX_AGE_SCHOOL_STUDENT_CARD;
  const dispatch = useDispatch();
  const { t } = useTranslation([
    "common",
    "customer",
    "snackbars",
    "validations",
  ]);

  const groupMembershipDocumentTypes = useSelector(
    (state: AppState) => state.groupMemberShipDocumentTypes
  );

  let groupMembershipDocumentTypeOptions =
    groupMembershipDocumentTypes.groupMembershipDocumentTypes.map((documentType) => {
      const localeLabel = `label_${getCurrentLanguage()}` as keyof typeof documentType;
      return {
        key: documentType.id,
        value: documentType.id,
        label: "" + documentType[localeLabel] || documentType.label,
      };
    });

  const [age, setAge] = useState<number | undefined>(undefined);
  /* TODO Refactor to avoid workaround and multiple rerendering */

  useEffect(() => {
    if (!isRegistrationPage && !currentUser) {
      getCurrentUserData(dispatch);
    }
  }, [userIsLoading]);

  useEffect(() => {
    if (!isRegistrationPage && userIsLoading) {
      getCurrentUserData(dispatch);
      user = currentUser;
      setUserIsLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      !groupMembershipDocumentTypes.isLoading &&
      !groupMembershipDocumentTypes.groupMembershipDocumentTypesLoaded
    ) {
      dispatch(getGroupMembershipDocumentTypes());
    }
  }, [groupMembershipDocumentTypes]);

  useEffect(() => {
    if (currentUser?.birthday) {
      const utcToday = new Date(
        currentDateLocal.getTime() + currentDateLocal.getTimezoneOffset() * 60000
      );
      const birthdayParts = currentUser.birthday.split(".");
      const birthDate = new Date(
        parseInt(birthdayParts[2]),
        parseInt(birthdayParts[1]) - 1,
        parseInt(birthdayParts[0])
      );
      const calcAge =
        utcToday.getFullYear() -
        birthDate.getFullYear() -
        (utcToday.getMonth() < birthDate.getMonth() ||
        (utcToday.getMonth() === birthDate.getMonth() &&
          utcToday.getDate() < birthDate.getDate())
          ? 1
          : 0);

      setAge(calcAge);
    }
  }, [currentUser]);

  // Remove school student card when person is older than maxAge (18)
  if (!age || !maxAge || age >= parseInt(maxAge)) {
    const newArray = groupMembershipDocumentTypeOptions.filter(
      (item) => item.label !== SCHOOL_STUDENT_CARD_LABEL
    );
    groupMembershipDocumentTypeOptions = newArray;
  }

  if (user === undefined && !isRegistrationPage) {
    return <Loader loadingText={t("waitNote", { ns: "common" })} />;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <p>{t("docUpload.instructionOnRegistration", {ns: "customer"})}</p>
        </Grid>
      </Grid>
      {isRegistrationPage ? (
          <>
          <Grid item xs={12}>
            <MembershipDocumentSelection
              documentTypeIsSelected={documentTypeIsSelected}
              setDocumentTypeIsSelected={setDocumentTypeIsSelected}
              isRegistrationPage={isRegistrationPage}
              groupMembershipDocument={groupMembershipDocument}
              setGroupMembershipDocument={setGroupMembershipDocument}
              files={files}
              setFiles={setFiles}
            />
          </Grid>
          <Grid item xs={12}>
            {documentTypeIsSelected ? (
              <MembershipDocumentUploadArea files={files} setFiles={setFiles} />
            ) : null}
          </Grid>
        </>
      ) : null}
      {!isRegistrationPage && (
        <>
          <MembershipDocumentUploadArea files={files} setFiles={setFiles} />
          <MembershipDocumentSelection
            documentTypeIsSelected={documentTypeIsSelected}
            setDocumentTypeIsSelected={setDocumentTypeIsSelected}
            isRegistrationPage={isRegistrationPage}
            groupMembershipDocument={groupMembershipDocument}
            setGroupMembershipDocument={setGroupMembershipDocument}
            files={files}
            setFiles={setFiles}
          />
        </>
      )}
    </>
  );
};
